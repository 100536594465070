import axios from "axios";

const API = 'https://openapi.betpay.money';

export async function fetchData(orderId) {

  try {
    const response = await axios.get(
      API + `/api/Widget/payin/${orderId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function setSessionId(orderId, sessionId) {

  try {
    const response = await axios.post(
      API + `/api/Widget/payin/${orderId}/session/${sessionId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function fetchBankInfo(orderId) {
  try {
    const response = await axios.get(
      API + `/api/Widget/paymentmethods/${orderId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function confirmPayment(orderId) {
  try {
    const response = await axios.post(
      API + `/api/widget/payin/paid/${orderId}`
    );
    if (response.status === 200) {
      return true;
    } else {
      return response.statusText;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function selectPaymentMethod(orderId, paymentTypeId) {
  const body = JSON.stringify({
    orderId: orderId,
    paymentMethodTypeId: paymentTypeId,
  });
  try {
    const response = await axios.post(
      API + "/api/Widget/payin/selectPaymentMethod",
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return true;
    } else {
      return response.statusText;
    }
  } catch (error) {
    console.log(error);
    console.log(body);
    throw error;
  }
}
