import React, { useState, useEffect, useCallback } from "react";
import "./style.scss";
import BankSelector from "./modules/bank_selector";
import OrderInfo from "./models/order_info.js";
import Status from "./modules/status";
import OrderForm from "./modules/form";
import BankItem from "./models/bank";
// eslint-disable-next-line
import { fetchData, fetchBankInfo, confirmPayment, setSessionId } from "./api";

function App() {
  const orderId = getOrderIdFromUrl();
  const [orderInfo, setOrderInfo] = useState(new OrderInfo());
  const [bankList, setBankList] = useState(undefined);
  const [error, setError] = useState(null);
  const statusEnum = {
    loading: -1,
    new: 0,
    inProgress: 1,
    paid: 2,
    completed: 3,
    canceled: 4,
    dispute: 5,
    clientDisputeWin: 6,
    traderDisputeWin: 7,
    expired: 8,
  };

  function getOrderIdFromUrl() {
    const url = window.location.href;
    const regex = /\/([^/]+)$/;
    const match = url.match(regex);
    const orderId = match ? match[1] : null;
    return orderId;
  }

  const updateData = useCallback(async () => {

    try {
      const data = await fetchData(orderId);
      const info = OrderInfo.fromJSON(data);
      if (!info.isSame(orderInfo)) {
        setOrderInfo(info);
      }
      setError(null);
    } catch (error) {
      if (orderInfo.orderId === undefined) {
        console.log(error);
        setError(error);
      }
    }
  }, [orderId, orderInfo]);



  const getBankInfo = useCallback(async () => {
    try {
      if (orderInfo.status === 0) {
        const data = await fetchBankInfo(orderId);
        const bankItems = data.map((item) => new BankItem(item.id, item.name));
        if (bankItems.length >= 0) {
          setBankList(bankItems);
        }
        if (!error) {
          setError(null);
        }
      } else {
        setBankList([]);
      }
    } catch (error) {
      console.log(error);
      if (orderInfo.orderId === undefined) {
        setError(error);
      }
    }
  }, [ orderId, orderInfo, error ]);

  const init = useCallback(async () => {

    await updateData();

    await getBankInfo();
  }, [ getBankInfo, updateData]);

  const onConfirmPayment = async (event) => {
    event.preventDefault();

    try {
      const success = await confirmPayment(orderId);
      if (success) {
        updateData();
      } else {
        console.log("Failed to confirm payment");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    init();
    const intervalId = setInterval(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
      updateData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [orderId, orderInfo, init, updateData]);

  if (error !== null) {
    return (
      <div className="__container">
        <div className="placeholder">Not Found</div>
      </div>
    );
  } else if (bankList !== undefined && !new OrderInfo().isSame(orderInfo)) {
    switch (orderInfo.status) {
      case statusEnum.new:
        return (
          <BankSelector
            bankList={bankList}
            amount={orderInfo.amount}
            orderId={orderInfo.orderId}
            onClick={updateData}
          />
        );
      case statusEnum.inProgress:
        return (
          <OrderForm
            orderInfo={orderInfo}
            onConfirmPayment={onConfirmPayment}
          />
        );
      default:
        return (
          <Status
            statusValue={orderInfo.status}
            redirectUrl={orderInfo.redirectUrl}
          />
        );
    }
  } else {
    return <Status statusValue={-1} />;
  }
}

export default App;
