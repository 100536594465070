import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function Timer(props) {
  const [timer, setTimer] = useState(props.timer);
  const [timerIsActive, setTimerIsActive] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (timer >= 0) {
      setTimerIsActive(true);
    } else {
      setTimerIsActive(false);
    }
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  if (timerIsActive) {
    return (
      <footer className="form__footer footer-form">
        <span className="footer-form__time">
          Час <i>{formatTime(timer)}</i>
        </span>
      </footer>
    );
  } else {
    return <div></div>;
  }
}
Timer.propTypes = {
  timer: PropTypes.number,
};

export default Timer;
