import React, { useEffect, useState } from "react";
import "../scss/alert.scss";
import PropTypes from "prop-types";

function Status(props) {
  const statusEnum = {
    loading: -1,
    new: 0,
    inProgress: 1,
    paid: 2,
    completed: 3,
    canceled: 4,
    dispute: 5,
    clientDisputeWin: 6,
    traderDisputeWin: 7,
    expired: 8,
  };

  const renderStatus = () => {
    switch (props.statusValue) {
      case statusEnum.loading:
        return (
          <StatusItem
            iconPath="./images/loading.svg"
            title="Завантаження..."
            subtitle="Очікуйте будь ласка"
          />
        );
      case statusEnum.completed:
      case statusEnum.clientDisputeWin:
        return (
          <DoneStatusItem
            iconPath="./images/done.svg"
            title="Готово"
            subtitle="Ваше замовлення успішно виконано"
            redirectUrl={props.redirectUrl}
          />
        );
      case statusEnum.paid:
      case statusEnum.dispute:
        return (
          <StatusItem
            iconPath="./images/loading.svg"
            title="Підтвердження оплати"
            subtitle="Це може тривати до 15 хв. Не закривайте сторінку!"
          />
        );
      case statusEnum.canceled:
      case statusEnum.traderDisputeWin:
        return (
          <StatusItem
            iconPath="./images/canceled.svg"
            title="Скасовано"
            subtitle="Ваше замовлення скасовано"
          />
        );
      case statusEnum.expired:
        return (
          <StatusItem
            iconPath="./images/expired.svg"
            title="Термін дії минув"
            subtitle="Термін дії транзакції минув"
          />
        );
      default:
        return <div>{props.statusValue}</div>;
    }
  };

  return <main className="__container">{renderStatus()} </main>;
}

function StatusItem(props) {
  return (
    <div className={props.className || "alert"}>
      <img
        className="alert__img"
        src={props.iconPath}
        width="128"
        height="128"
        alt="alert"
      />
      <h2 className="alert__title">{props.title}</h2>
      <p className="alert__subtitle">{props.subtitle}</p>
    </div>
  );
}

function DoneStatusItem(props) {
  const [count, setCount] = useState(5);

  useEffect(() => {
    if (props.redirectUrl !== null) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      if (count === 0) {
        clearInterval(timer);

        window.location.replace(props.redirectUrl);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [count, props.redirectUrl]);

  function renderTimer() {
    if (props.redirectUrl !== null) {
      return (
        <h6 className="redirectTimer">
          {"Вас буде переправлено на "}
          <a href={props.redirectUrl} className="redirectTimer__link">
            сторінку
          </a>
          {` через ${count}c`}
        </h6>
      );
    }
  }

  return (
    <div>
      <StatusItem
        iconPath="./images/done.svg"
        title="Готово"
        subtitle="Ваше замовлення успішно виконано"
      />
      {renderTimer()}
    </div>
  );
}

StatusItem.propTypes = {
  iconPath: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

Status.prototype = {
  statusValue: PropTypes.number,
  redirectUrl: PropTypes.string,
};

DoneStatusItem.propTypes = {
  iconPath: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default Status;
