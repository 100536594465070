import PropTypes from "prop-types";

function BankItemComponent(props) {
  return (
    <li className={props.className} onClick={props.onSelect}>
      {props.bank.name}
    </li>
  );
}

BankItemComponent.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  bank: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default BankItemComponent;
