class OrderInfo {
  constructor(
    orderId,
    status,
    timer,
    cardNumber,
    cardOwnerName,
    amount,
    redirectUrl
  ) {
    this.orderId = orderId;
    this.status = status;
    this.timer = timer;
    this.cardNumber = cardNumber;
    this.cardOwnerName = cardOwnerName;
    this.amount = amount;
    this.redirectUrl = redirectUrl;
  }

  isSame(info) {
    return (
      info.orderId === this.orderId &&
      info.status === this.status &&
      info.cardNumber === this.cardNumber &&
      info.cardOwnerName === this.cardOwnerName &&
      info.amount === this.amount &&
      info.redirectUrl === this.redirectUrl
    );
  }

  static fromJSON(json) {
    const {
      orderId,
      status,
      timer,
      cardNumber,
      cardOwnerName,
      amount,
      redirectUrl,
    } = json;
    return new OrderInfo(
      orderId,
      status,
      timer,
      cardNumber,
      cardOwnerName,
      amount,
      redirectUrl
    );
  }
}

export default OrderInfo;
