import React, { useState } from "react";
import PropTypes from "prop-types";
import BankItemComponent from "./bank_item.js";
import BankItem from "../models/bank.js";
import { selectPaymentMethod } from "../api.js";

function BankSelector(props) {
  const [selectMode, setSelectMode] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setSelectMode(!selectMode);
  };

  const onSelect = (value) => {
    setSelectedBank(value);
    setSelectMode(false);
  };

  const onClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const success = await selectPaymentMethod(props.orderId, selectedBank.id);

      if (success) {
        props.onClick();
      } else {
        console.log("Failure to set Payment Method:", success);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const renderBanksList = () => {
    return props.bankList.map((e, index) => {
      var isSelected = false;
      if (selectedBank !== null) {
        isSelected = selectedBank.id === e.id;
      }
      return (
        <BankItemComponent
          key={index}
          className={`select__item ${isSelected ? "select__item_active" : ""}`}
          bank={e}
          onSelect={() => onSelect(e)}
        />
      );
    });
  };

  const bankSelected = () => {
    return selectedBank !== null;
  };

  return (
    <main className="__container">
      <div
        className="select__wrapper"
        onClick={handleClick}
        style={
          selectMode
            ? { position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }
            : {}
        }
      ></div>
      <form className={`form ${bankSelected() ? "selected" : ""}`}>
        <h2 className="form__title">Виберіть свій банк</h2>
        <fieldset className="form__fieldset form__fieldset_select">
          <label className="form__label">Банк</label>
          <button
            disabled={loading}
            onClick={handleClick}
            type="button"
            className={`form__select ${loading ? "form__select-loading" : ""}`}
          >
            {selectedBank !== null ? selectedBank.name : "Виберіть"}
          </button>
          <div className={`select ${selectMode ? "select__open" : ""}`}>
            <ul className="select__list">{renderBanksList()} </ul>
          </div>
        </fieldset>
        <fieldset className="form__fieldset">
          <label className="form__label">Сума</label>
          <div className="form__amount amount">
            <span className="amount__number">{props.amount} грн</span>
          </div>
        </fieldset>
        <button
          className={`form__submit ${loading ? "form__submit-loading" : ""}`}
          disabled={!bankSelected() && loading}
          onClick={bankSelected() ? onClick : null}
        >
          Вибрати
        </button>
      </form>
    </main>
  );
}
BankSelector.propTypes = {
  amount: PropTypes.number,
  orderId: PropTypes.string,
  onClick: PropTypes.func,
  bankList: PropTypes.arrayOf(PropTypes.instanceOf(BankItem)),
};
export default BankSelector;
