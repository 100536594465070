class BankItem {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  isValid() {
    return (
      this.id !== undefined &&
      this.id !== "" &&
      this.name !== undefined &&
      this.name !== ""
    );
  }

  toJson() {
    return JSON.stringify({
      id: this.id,
      name: this.name,
    });
  }
}

export default BankItem;
