import PropTypes from "prop-types";
import Timer from "./timer";
import React, { useState } from "react";

function OrderForm(props) {
  const [loading, setLoading] = useState(false);
  const [fade, setFade] = useState(false);

  const formattedCardNumber = props.orderInfo.cardNumber.replace(
    /\d{4}(?=\d)/g,
    "$& "
  );

  const onClick = async (event) => {
    event.preventDefault();

    setLoading(true);
    await props.onConfirmPayment(event);
    setLoading(false);
  };

  const handleCopy = () => {
    setFade(true);
    const textToCopy = props.orderInfo.cardNumber;
    navigator.clipboard.writeText(textToCopy);
  };
  return (
    <main className="__container">
      <h2 className="form__title">Виконайте платіж на банківську карту</h2>
      <form className="form selected">
        <fieldset className="form__fieldset">
          <label className="form__label">Номер карти</label>
          <div className="form__wrapper">
            <span className="form__card">{formattedCardNumber}</span>
            <button
              className={`amount__btn amount__copy ${fade ? "fade" : ""}`}
              onClick={handleCopy}
              onAnimationEnd={() => setFade(false)}
              type="button"
            ></button>
          </div>
        </fieldset>

        <fieldset className="form__fieldset">
          <label className="form__label">Власник рахунку</label>
          <div className="form__note note">
            <span className="note__number">
              {props.orderInfo.cardOwnerName}
            </span>
          </div>
        </fieldset>

        <fieldset className="form__fieldset">
          <label className="form__label">Сума</label>
          <div className="form__amount amount">
            <span className="amount__number">{props.orderInfo.amount} грн</span>
          </div>
        </fieldset>

        <div className="form__alert">
          Вкажіть точну суму платежу. В іншому випадку транзакція може бути
          втрачена. Натисніть кнопку тільки якщо ви здійснили платіж!
        </div>

        <button
          className={`form__submit ${loading ? "form__submit-loading" : ""}`}
          onClick={onClick}
          disabled={loading}
        >
          Підтвердити платіж
        </button>
        <Timer timer={props.orderInfo.timer} />
      </form>
    </main>
  );
}

OrderForm.propTypes = {
  onConfirmPayment: PropTypes.func,
  orderInfo: PropTypes.shape({
    orderId: PropTypes.string,
    status: PropTypes.number,
    timer: PropTypes.number,
    cardNumber: PropTypes.string,
    cardOwnerName: PropTypes.string,
    amount: PropTypes.number,
  }),
};

export default OrderForm;
